import React from "react"
import Wrapper from "../../../components/insight_wrapper"

import BannerImage from "../../../images/editorial/insights/client-success-story-banner.jpg"
import { Helmet } from "react-helmet"

import Figure1 from "../../../images/svg/threemonth-collection-msm.svg"
import Figure2 from "../../../images/svg/annual-collection-msm.svg"

const GetBannerImage = (BannerImage) => {
  return <img src={BannerImage} alt="" />
}

const Page = () => (

  <Wrapper title={"Esteemed Radiology Group Realizes the Value of Finding a True RCM Partner"} banner={GetBannerImage(BannerImage)}
    bannerAlt="Doctor and Patient Shaking Hands" >

    <Helmet>
      <meta property='og:title' content="Esteemed Radiology Group Realizes the Value of Finding a True RCM Partner" />
      <meta property='og:image' content={{ BannerImage }} />
      <meta property='og:description' content='Description that will show in the preview' />
      <meta property='og:url' content='//msmhealth.com/news_and_insights/four_myths_that_fuel_physician_burnout' />

    </Helmet>

    < h3 style={{ 'marginTop': 25 }}> Challenge</h3 >
    <p>A well regarded, California based Radiology group knew their billing vendor wasn’t hitting the mark. The group had chosen a nationally recognized vendor that specialized in Radiology but were sorely disappointed when they realized the focus was on sales and sizzle over substance. The radiologists were working hard managing the clinical side of their business, while being unnecessarily inundated with nuisance issues related to their revenue cycle. The group was flooded with administrative burden and their revenue was stagnant. Feeling trapped and unsatisfied, they needed to make a change. The group ultimately chose to replace their revenue cycle service provider. They sought to find a company capable of immediately stabilizing their cash, reducing their revenue cycle administrative burden, and committing to adherence of transparent performance metrics.  </p>

    < h3 > Solution</h3 >
    <p>
      Upon initial discussion and review of the practice’s metrics, MSM was confident they could make a profound impact for this group. Within 90 days of going live with the MSM solution, the practice had a 22% increase in monthly collections. There was no volume change in the practice. The group was doing the same amount of work yet increased their monthly take-home by $74K. This revenue increase was sustained throughout the first year of MSM’s service, resulting in a $912K increase in collections. The Radiology group continues to benefit from the same financial performance today.
</p>

    <h3>Recipe for Success </h3>
    <p>
      When engaged with a new client, MSM invests significant time and resources into a robust discovery process laying the initial foundation for future success. During the discovery phase of this client onboarding, numerous issues were brought to light including a leaky charge capture process, a significant amount of untouched and aged-out claims, as well as underpayments from numerous insurance carriers. MSM systematically implemented customized solutions that focused on the underlying cause of these issues- charge capture validation, revenue integrity, clean claim submission and overall reduction of days in AR.
</p>

    <p>
      To tackle these challenges the team implemented a four-step process:
 </p>

    <ol>

      <li>
        <h4>Interface Reconciliation</h4>
        <p> Down to the individual charge, MSM built a process to identify any missing charges ensuring studies are captured and billed for.
 </p>
      </li>

      <li>
        <h4>Administrative Transfer</h4>
        <p> Our team attained access to the groups affiliated hospital systems, allowing MSM to hunt and collect missing information without utilizing valuable Physician time.</p>
      </li>

      <li> <h4>Data Validation</h4>
        <p>A pre-claim data validation processes was developed to ensure claims went out clean the very first time.
 </p>
      </li>
      <li>
        <h4>Contract Review</h4>
        <p>We reviewed and abstracted all of the client’s contracts to create a tailored denial management process, ensuring that all potential revenue was being realized.</p>
      </li>
    </ol>

    <figure>
      <div className="svg"><img src={Figure1} /></div>
      <p>From $331K in average monthly collections (prior billing company) to <span>$404K in average monthly collections by MSM</span></p>
    </figure>

    <figure>

      <div className="svg"><img src={Figure2} /></div>
      <p>From $3,970,000 in annual collections (prior billing company) to <span>$4,882,000 in annual collections by MSM</span> </p>
    </figure>

    <p>MSM defined stringent quality of service standards as a foundation to this new partnership. Using a balance of technology and human expertise to tightly monitor service level targets, MSM created a path to a sustainable long-term partnership. No longer needing to rely on gut instinct, the Radiology group was equipped with transparent on-demand tools allowing them to best manage their business. In addition to the four-step process, a custom RCM dashboard was put in place. At a single click the group could access real-time report cards measuring their practices’ performance, realized and forecasted revenue, physician productivity, referring patterns, etc. With a customized solution in place, the radiology group turned a deteriorating reactive billing process into a profitable proactive one. </p>


  </Wrapper >

)

export default Page;