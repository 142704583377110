import React from "react"

import "../styles/index.scss"
import "../styles/secondary_pages.scss"

import Layout from "../components/layout"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faTwitter } from '@fortawesome/free-brands-svg-icons'

import { Link, graphql } from "gatsby"
import { Location } from "@reach/router"
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon } from "react-share"

function HTMLtitle(titletoconvert) {
  return <>{titletoconvert}</>
}

export default class Wrapper extends React.Component {


  render() {
    const link = this.props.link;
    const title = this.props.title;
    const article = this.props.children;
    const strippedTitle = title.replace(/(<([^>]+)>)/ig, "");
    const banner = this.props.banner;
    const bannerAlt = this.props.bannerAlt;

    return (
      <React.Fragment>


        <Layout>
          <section className="article-content">
            <div className="breadcrumb">
              <Link to="/news_and_insights/insights/">Insights</Link>  /  <span>{strippedTitle}</span>
            </div>
            <div className="header">
              <h1 dangerouslySetInnerHTML={{ __html: title }}>

              </h1>
              <div className="social">
                <Location>

                  {({ location }) => (
                    <>

                      <FacebookShareButton url={location.href}>
                        <FacebookIcon size={22} round={true} bgStyle={{ 'fill': 'black' }} />
                      </FacebookShareButton>
                      <LinkedinShareButton url={location.href} >
                        <LinkedinIcon size={22} round={true} bgStyle={{ 'fill': 'black' }} />
                      </LinkedinShareButton>
                      <TwitterShareButton url={location.href} >
                        <TwitterIcon size={22} round={true} bgStyle={{ 'fill': 'black' }} />
                      </TwitterShareButton>
                    </>
                  )}

                </Location>
              </div>

              {banner}

            </div>
            <article>
              {article}

            </article>
          </section >


          <section className="container ctr-cta" style={{ backgroundColor: "#16cac3", color: "#fff" }}>
            <h2>Are you ready to start partnering with MSM?</h2>
            <p>  Take the first step, and learn more about MSM’s services today.</p>
            <button className="btn-primary btn-inverse" style={{ backgroundColor: "#fff", color: "#16cac3" }}>
              <Link to="/contact">Get Started</Link>
            </button>
          </section>

        </Layout >
      </React.Fragment >
    )
  }
}

export const bannerQuery = graphql`query {
  imageOne: file(relativePath:{eq:"editorial/insights/revenue-cycle-management-choosing-the-right-provider-can-make-all-the-difference-2000x1383.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        base64
        tracedSVG
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        originalImg
        originalName
      }
    }
  }
}`